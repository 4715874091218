  <div id="wrapper">

        <!-- Top Bar Start -->
       <app-sidebar></app-sidebar>
        <!-- Top Bar End -->

        <!-- ========== Left Sidebar Start ========== -->
      <app-menu-general></app-menu-general>
        <!-- Left Sidebar End -->

       <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="content-page">
            <!-- Start content -->
            <div class="content">

               

               <div class="container-fluid">
                    <div class="page-title-box">

                        <div class="row align-items-center ">
                            <div class="col-md-8">
                                <div class="page-title-box">
                                    <h4 class="page-title">Formulario de Vacunados</h4>
                                  
                                </div>
                            </div>

                         
                        </div>
                    </div>
                    <!-- end page-title -->






                    <form role="form" [formGroup]="Form">

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">

                            





    <div class="form-group row">
                                    <label for="example-text-input" class="col-sm-2 col-form-label">Estados</label>
                                      <div class="col-sm-10">
                                       <select type="text"  class="form-control" placeholder="Ingresar"
                                        [ngClass]=" Form.controls['estado_id'].valid ? 'is-valid state-valid' : 'is-invalid state-invalid'"
                                        formControlName="estado_id" [(ngModel)]="vacunas.estado_id"  (change)="getMunicipios($event.target.value)">
                       
                                        <option *ngFor="let estado of estados" value="{{estado.id}}">{{estado.estado}}</option>
                                       </select>
                                   <div class="invalid-feedback"
                                    *ngIf="Form.controls['estado_id'].touched && 
                                      Form.controls['estado_id'].errors?.required" >
                                        Estado es Obligatorio...
                                  </div>
                                </div>
                               </div>







                              
                                <div class="form-group row"  >
                                    <label for="example-text-input" class="col-sm-2 col-form-label">Municipios</label>
                                      <div class="col-sm-10">
                                      <select type="text"  class="form-control" placeholder="Ingresar "
                                      [ngClass]=" Form.controls['municipio_id'].valid ? 'is-valid state-valid' : 'is-invalid state-invalid'"
                                       formControlName="municipio_id" [(ngModel)]="vacunas.municipio_id" (change)="getCentroSalud($event.target.value)">
                    
                                      <option *ngFor="let municipio of municipios" value="{{municipio.id}}">{{municipio.municipio}}</option>
                                    </select>

                                    <div class="invalid-feedback"
                                    *ngIf="Form.controls['municipio_id'].touched && 
                                    Form.controls['municipio_id'].errors?.required"   >
                                        Municipio es Obligatorio...
                                    </div>
                                </div>
                              </div>



                               <div class="form-group row">
                                    <label for="example-text-input" class="col-sm-2 col-form-label">Centro Salud</label>
                                      <div class="col-sm-10">
                                      <select type="text"  class="form-control" placeholder="Ingresar "
                                      [ngClass]=" Form.controls['centro_salud_id'].valid ? 'is-valid state-valid' : 'is-invalid state-invalid'"
                                       formControlName="centro_salud_id" [(ngModel)]="vacunas.centro_salud_id">
                    
                                      <option *ngFor="let centrossalud of centrosalud" value="{{centrossalud.id}}">{{centrossalud.centro_salud}}</option>
                                    </select>

                                    <div class="invalid-feedback"
                                    *ngIf="Form.controls['centro_salud_id'].touched && 
                                    Form.controls['centro_salud_id'].errors?.required"   >
                                        Centro de Salud es Obligatorio...
                                    </div>
                                </div>
                              </div>




                                 <div class="form-group row">
                                    <label for="example-text-input" class="col-sm-2 col-form-label">Grupo Especiales</label>
                                      <div class="col-sm-10">
                                      <select type="text"  class="form-control" placeholder="Ingresar "
                                      [ngClass]=" Form.controls['grupo_especial_id'].valid ? 'is-valid state-valid' : 'is-invalid state-invalid'"
                                       formControlName="grupo_especial_id" [(ngModel)]="vacunas.grupo_especial_id">
                    
                                      <option *ngFor="let grupoespeciale of grupoespeciales" value="{{grupoespeciale.id}}">{{grupoespeciale.grupo_especial}}</option>
                                    </select>

                                    <div class="invalid-feedback"
                                    *ngIf="Form.controls['grupo_especial_id'].touched && 
                                    Form.controls['grupo_especial_id'].errors?.required"   >
                                      Grupo Especial es Obligatorio...
                                    </div>
                                </div>
                              </div>  






                                

              <div class="col-md-6 offset-md-3">
                <h3>Subir Archivo</h3>

                <div class="form-group">
                    <input
                        type="file"
                        name="file"
                        (change)="fileProgress($event)"
                    >
                </div>

                <div
                    class="progress"
                    *ngIf="fileUploadProgress"
                >
                    <div
                        class="progress-bar w-100"
                        role="progressbar"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        Subiendo Archivo: {{ fileUploadProgress }}
                    </div>
                </div>

               

                <div
                    class="mb-3"
                    *ngIf="uploadedFilePath"
                >{{uploadedFilePath}}</div>
            </div>


                <h3>Información del archivo de carga:</h3>
                <p class="font-weight-bold">Columna A: Nacionalidad (Ejemplo: V,E,P).</p>
                <p class="font-weight-bold">Columna B: Cedula.</p>
                <p class="font-weight-bold">Columna C: Nombre Completo.</p>
                <p class="font-weight-bold">Columna D: Fecha de Nacimiento (Ejemplo: 01-09-1943).</p>
                <p class="font-weight-bold">Columna E: Edad.</p>
                <p class="font-weight-bold">Columna F: Telefono.</p>
                <p class="font-weight-bold">Columna G: Dirección.</p>


    
                    </div>
                    <!-- /.card-body -->
    
            <div class="card-footer">
                <button
                class="btn btn-primary"
                (click)="SubirImagen()"
                [disabled]="!boton"
            >Cargar Archivo Excel 
          </button>

  
 
























          

                                </div>
                            </div>

                            
                        </div>
                        <!-- end col -->

                        
                    </div>
                    <!-- end row -->










                    </form>

                </div>



















                <!-- container-fluid -->

            </div>
            <!-- content -->

            <footer class="footer">
              
            </footer>

        </div>
        <!-- ============================================================== -->
        <!-- End Right content here -->
        <!-- ============================================================== -->

    </div>