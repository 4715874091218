  <div class="left side-menu">
            <div class="slimscroll-menu" id="remove-scroll">

                <!--- Sidemenu -->
                <div id="sidebar-menu">
                    <!-- Left Menu Start -->
                    <ul class="metismenu" id="side-menu">
                        <li class="menu-title">Menu Administrador</li>
                        <li>
                            <a [routerLink]="['/inicio']" class="waves-effect">
                                <i class="dripicons-meter"></i> <span> Inicio </span>
                            </a>
                        </li>




                        <li>
                            <a   (click)="VacunasMenu = !VacunasMenu" class="waves-effect mm-active"><i class="dripicons-mail"></i><span> Vacunados 
                            <span class="float-right menu-arrow"><i class="mdi mdi-chevron-right"></i></span> </span></a>
                            <ul class="submenu mm-collapse " [ngClass]=" VacunasMenu ? 'mm-show' : 'secondary'" >
                                <li><a [routerLink]="['/vacunados/admin/form']">Agregar </a></li>
                                <li><a [routerLink]="['/vacunados/admin/list']">Listado </a></li>
                                 <li><a [routerLink]="['/vacunados/admin/list/completa/qr']">Vacunados Con QR </a></li>
                                 <li><a [routerLink]="['/vacunados/master/patria/form']">Vacunados Master </a></li>
                           
                            </ul>
                        </li>


                        <li>
                            <a   (click)="VacunasMasivas = !VacunasMasivas" class="waves-effect mm-active"><i class="dripicons-mail"></i><span> Importar Vacunados Masivo 
                            <span class="float-right menu-arrow"><i class="mdi mdi-chevron-right"></i></span> </span></a>
                            <ul class="submenu mm-collapse " [ngClass]=" VacunasMasivas ? 'mm-show' : 'secondary'" >
                                <li><a [routerLink]="['/vacunados/importar/admin/form']">Importar Excel </a></li>
                                <li><a [routerLink]="['/vacunados/importar/admin/list']">Listado </a></li>
                                
                           
                            </ul>
                        </li>



                           <li>
                            <a   (click)="VacunasRecibidas = !VacunasRecibidas" class="waves-effect mm-active"><i class="dripicons-mail"></i><span> Vacunas Recibidas
                            <span class="float-right menu-arrow"><i class="mdi mdi-chevron-right"></i></span> </span></a>
                            <ul class="submenu mm-collapse " [ngClass]=" VacunasRecibidas ? 'mm-show' : 'secondary'" >
                                <li><a [routerLink]="['/vacunas_recibidas/admin/form']">Agregar </a></li>
                                <li><a [routerLink]="['/vacunas_recibidas/admin/list']">Listado </a></li>
                               
                           
                            </ul>
                        </li>            
















                           <li>
                            <a   (click)="VacunasPerdidas = !VacunasPerdidas" class="waves-effect mm-active"><i class="dripicons-mail"></i><span> Vacunas Perdidas 
                            <span class="float-right menu-arrow"><i class="mdi mdi-chevron-right"></i></span> </span></a>
                            <ul class="submenu mm-collapse " [ngClass]=" VacunasPerdidas ? 'mm-show' : 'secondary'" >
                                <li><a [routerLink]="['/vacunas_perdidas/admin/form']">Agregar </a></li>
                                  <li><a [routerLink]="['/vacunas_perdidas/admin/list']">Listado </a></li>
                           
                            </ul>
                        </li>

                          <li>
                            <a   (click)="CentroSaludMenu = !CentroSaludMenu" class="waves-effect mm-active"><i class="dripicons-mail"></i><span> Centro Salud 
                            <span class="float-right menu-arrow"><i class="mdi mdi-chevron-right"></i></span> </span></a>
                            <ul class="submenu mm-collapse " [ngClass]=" CentroSaludMenu ? 'mm-show' : 'secondary'" >
                                <li><a [routerLink]="['/centrosalud/admin/form']">Agregar </a></li>
                                  <li><a [routerLink]="['/centrosalud/admin/list']">Listado </a></li>
                           
                            </ul>
                        </li>     

                          <li>
                            <a   (click)="TipoVacuna = !TipoVacuna" class="waves-effect mm-active"><i class="dripicons-mail"></i><span> Tipo de Vacunas 
                            <span class="float-right menu-arrow"><i class="mdi mdi-chevron-right"></i></span> </span></a>
                            <ul class="submenu mm-collapse " [ngClass]=" TipoVacuna ? 'mm-show' : 'secondary'" >
                                <li><a [routerLink]="['/tiposvacunas/admin/form']">Agregar </a></li>
                                  <li><a [routerLink]="['/tiposvacunas/admin/list']">Listado </a></li>
                           
                            </ul>
                        </li>

                          <li>
                            <a   (click)="GruposEspeciales = !GruposEspeciales" class="waves-effect mm-active"><i class="dripicons-mail"></i><span> Grupos Especiales 
                            <span class="float-right menu-arrow"><i class="mdi mdi-chevron-right"></i></span> </span></a>
                            <ul class="submenu mm-collapse " [ngClass]=" GruposEspeciales ? 'mm-show' : 'secondary'" >
                                <li><a [routerLink]="['/grupo_especial/admin/form']">Agregar </a></li>
                                  <li><a [routerLink]="['/grupo_especial/admin/list']">Listado </a></li>
                           
                            </ul>
                        </li>

                       <li>
                            <a   (click)="UsuariosMenu = !UsuariosMenu" class="waves-effect mm-active"><i class="dripicons-mail"></i><span> Usuarios 
                            <span class="float-right menu-arrow"><i class="mdi mdi-chevron-right"></i></span> </span></a>
                            <ul class="submenu mm-collapse " [ngClass]=" UsuariosMenu ? 'mm-show' : 'secondary'" >
                                <li><a [routerLink]="['/usuarios/admin/form']">Agregar </a></li>
                                  <li><a [routerLink]="['/usuarios/admin/list']">Listado </a></li>
                           
                            </ul>
                        </li>
                      
                         <li>
                            <a  [routerLink]="['/vacunados/reportes/general/list']" class="waves-effect">
                                <i class="dripicons-meter"></i> <span> Reportes General </span>
                            </a>
                        </li>

                         <li>
                            <a  [routerLink]="['/vacunas/nacional/reportes']" class="waves-effect">
                                <i class="dripicons-meter"></i> <span> Reportes Nacional </span>
                            </a>
                        </li>


                       <li>
                            <a (click)="logout()" href="javascript:void(0)" class="waves-effect">
                                <i class="dripicons-meter"></i> <span> Cerrar Sesion </span>
                            </a>
                        </li>

                     

             

                  

                      



                     

                     

                    

                   

                    </ul>

                </div>
                <!-- Sidebar -->
                <div class="clearfix"></div>

            </div>
            <!-- Sidebar -left -->

        </div>