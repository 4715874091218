  <div id="wrapper">

        <!-- Top Bar Start -->
       <app-sidebar></app-sidebar>
        <!-- Top Bar End -->

        <!-- ========== Left Sidebar Start ========== -->
      <app-menu-general></app-menu-general>
        <!-- Left Sidebar End -->

       <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="content-page">
            <!-- Start content -->
            <div class="content">

               

               <div class="container-fluid">
                    <div class="page-title-box">

                        <div class="row align-items-center ">
                            <div class="col-md-8">
                                <div class="page-title-box">
                                    <h4 class="page-title">Actualizar Contraseña</h4>
                              
                                </div>
                            </div>

                         
                        </div>
                    </div>
                    <!-- end page-title -->




                                          



                    <form role="form" [formGroup]="Form">

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                                                
              



                            

                         
                                   


                                     <div class="form-group row">
                                    <label for="example-text-input" class="col-sm-2 col-form-label">Contraseña</label>
                                      <div class="col-sm-10">
                                        <input type="password" class="form-control"  placeholder="Ingresar Contraseña"
                                          [ngClass]=" Form.controls['password'].valid ? 'is-valid fa fa-check ' : 'is-invalid'"
                                          formControlName="password" [(ngModel)]="usuario.password">
                                        <div class="invalid-feedback"
                                            *ngIf="Form.controls['password'].touched && 
                                            Form.controls['password'].errors?.required"   >
                                              Contraseña es Obligatorio...
                                        </div>
                                           <p class="text-warning">Contraseña debe ser mayor a 5 caracteres...</p>

                                        <div class="invalid-feedback"
                                            *ngIf="Form.controls['password'].touched && 
                                            Form.controls['password'].errors?.minlength"   >
                                              Contraseña es Minimo 5...
                                        </div>
                                      </div>
                                    </div>

                                   

                                 






                            






                        







                        

                                 
                                 

                                  
                                  

                                   
                                
                                <button type="submit" *ngIf="edit" [disabled]="!usuario.password" class="btn btn-primary" (click)="ActualizarUsuario()">Actualizar</button>
                               
                                </div>
                            </div>

                            
                        </div>
                        <!-- end col -->

                        
                    </div>
                    <!-- end row -->










                    </form>

                </div>



















                <!-- container-fluid -->

            </div>
            <!-- content -->

            <footer class="footer">
            </footer>

        </div>
        <!-- ============================================================== -->
        <!-- End Right content here -->
        <!-- ============================================================== -->

    </div>