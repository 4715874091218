import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-vestadal',
  templateUrl: './menu-vestadal.component.html',
  styleUrls: ['./menu-vestadal.component.css']
})
export class MenuVestadalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
