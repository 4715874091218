import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-menu-nacional',
  templateUrl: './menu-nacional.component.html',
  styleUrls: ['./menu-nacional.component.css']
})
export class MenuNacionalComponent implements OnInit {

  usuarios: any =[];
  VacunasMenu: boolean = false;


  constructor(private auth: UserService) { }

  ngOnInit(): void {
    this.informacionUsuario();
  }


  informacionUsuario() {
    this.auth.profile().subscribe(
    res => {
    this.usuarios = res;
    // alert(this.usuarios.user.centro_salud_id);
    console.log('usuario data', res);
    
    },
    err => {
    alert('Sesion Experiada..');

    }
    );
    }

  
  logout() {
    this.auth.logout();
  }

}
