  <div id="wrapper">

        <!-- Top Bar Start -->
       <app-sidebar></app-sidebar>
        <!-- Top Bar End -->

        <!-- ========== Left Sidebar Start ========== -->
      <app-menu-general></app-menu-general>
        <!-- Left Sidebar End -->












        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="content-page">
            <!-- Start content -->
            <div class="content">

                <div class="container-fluid">
                    <div class="page-title-box">

                        <div class="row align-items-center ">
                            <div class="col-md-8">
                                <div class="page-title-box">
                                    <h4 class="page-title">Bienvenidos!!!</h4>
                                   
                                </div>
                            </div>

                          
                        </div>
                    </div>
                    <!-- end page-title -->

                  
                    <!-- end top-Contant -->

               
                    <!-- end row -->

                

                  

                </div>
                <!-- container-fluid -->

            </div>
            <!-- content -->

            <footer class="footer">
               
            </footer>

        </div>
        <!-- ============================================================== -->
        <!-- End Right content here -->
        <!-- ============================================================== -->

    </div>